<template>
    <div ref="append-article">
        <zw-sidebar @shown="shown" :title="$t('bank.title.assign_bank_transfer')">
            <div v-if="!loading" class="px-3 py-2">
                <b-card
                    border-variant="primary"
                    align="center"
                >
                    <b-card-text>
                        <b-row>
                            <b-col cols="6">
                                <b-row>
                                    <b-col class="text-right" cols="4"><strong>{{ $t('bank.label.number') }}:</strong></b-col>
                                    <b-col class="text-left" cols="8">{{ offeringData.offering.number }}</b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="text-right" cols="4"><strong>{{ $t('bank.label.customer') }}:</strong></b-col>
                                    <b-col class="text-left" cols="8">
                                        {{
                                            (offeringData.offering.billing_address.company || offeringData.offering.billing_address.firstname + ' ' +
                                                offeringData.offering.billing_address.lastname)
                                        }}
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="text-right" cols="4"><strong>{{ $t('bank.label.brutto') }}:</strong></b-col>
                                    <b-col class="text-left" cols="8">{{ offeringData.offering.brutto | priceFormat }}&euro;</b-col>
                                </b-row>
                                <b-row v-if="offeringData.invoices.length">
                                    <b-col class="text-right" cols="4"><strong>{{ $t('bank.label.invoice_name') }}:</strong></b-col>
                                    <b-col class="text-left" cols="8">{{ offeringData.invoices[0].name }}</b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <b-row>
                                    <b-col class="text-right" cols="4"><strong>{{ $t('bank.label.api_order_id') }}:</strong></b-col>
                                    <b-col class="text-left" cols="8">
                                        {{offeringData.offering.api_order_id}}
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="text-right" cols="4"><strong>{{ $t('bank.label.created_at') }}:</strong></b-col>
                                    <b-col class="text-left" cols="8">
                                        {{offeringData.offering.created_at | formatDate('DD.MM.YYYY HH:mm:ss')}}
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                    </b-card-text>
                </b-card>

                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="bank_transfers"
                                      columnsPrefix="bank_transfers.column."
                                      base-table="bank_transactions"
                                      :customSource="getThis()"
                                      :items="myProvider"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :actions-list="getActions()"
                            >
                                <template v-slot:top-actions>
                                    <b-form-checkbox
                                        class="ml-2"
                                        v-model="includeAssigned"
                                        switch
                                        value="1"
                                        unchecked-value="0"
                                        @change="includeAssignedChanged"
                                    >{{ $t('bank.label.include_assigned') }}
                                    </b-form-checkbox>
                                </template>
                                <template #cell(actions)="row">
                                    <b-dropdown class="mb-1"
                                                left
                                                variant="primary"
                                                size="sm"
                                    >
                                        <template #button-content>
                                            {{ $t('common.label.actions') }}
                                        </template>
                                        <b-dropdown-item
                                            @click="assignSingle(row.item.id)"
                                        >
                                            {{ $t('bank.actions.assign') }}
                                        </b-dropdown-item>
                                        <b-dropdown-item
                                            v-if="row.item.assigned"
                                            @click="unlink(row.item.id)"
                                        >
                                            {{ $t('bank.actions.unlink') }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </zw-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button :disabled="selected.length==0" block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from "@/bundles/erika_common_bundle/mixins/table-date-filter";

export default {
    name: 'AssignCreditBankTransferModal',
    mixins: [commonSave, commonTable, tableDateFilter],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            offeringData: {},
            saveFilters: true,
            tableUniqKey: 'assign-bank-credit-transfer',
            includeAssigned: false,
        }
    },
    methods: {
        ...mapGetters('BankTransfers', ['getTransfersTable']),
        ...mapGetters('Offering', ['getOffering']),
        shown() {
            this.$set(this, 'selected', [])
            this.form = JSON.parse(JSON.stringify(this.defaultForm))

            const offeringFetch = this.$store.dispatch('Offering/fetchOffering', this.payload.offering_id)

            Promise.all([offeringFetch])
                .then(() => {
                    this.offeringData = this.getOffering()
                    this.loading = false
                })


        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'BankTransfers/fetchTransfers', 'getTransfersTable');
        },
        assignSingle(id) {
            this.form.ids = [id]
            this.form.offering_id = this.payload.offering_id

            this.$store.dispatch('BankTransfers/assignToOffering', this.form)
                .then((response) => {
                    this.refreshTable()
                })
                .catch(errors => {
                    this.$refs['observer'].setErrors(errors)
                    this.showValidationError()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.form.ids = this.selected.map(row => row.id)
                    this.form.offering_id = this.payload.offering_id

                    this.$store.dispatch('BankTransfers/assignToOffering', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        unlink(id) {
            this.$store.dispatch('BankTransfers/unlink', {id}).finally(() => {
                this.refreshTable()
            })
        },
        getActions() {
            return []
        },
        includeAssignedChanged(val) {
            this.$set(this.filter, 'include_assigned', val)
            this.$set(this.filter, 'filterChanged', true)
        },
    },
}
</script>